import React from 'react'
import Layout from 'layouts/index'
import Fade from 'react-reveal/Fade'

import TitleBox from 'components/TitleBox'
import UnderlinedText from 'components/UnderlinedText'

import {
  PageContainer,
  ContentWrapper,
  ContentText,
} from '../pageStyles/sobre-nos.styles'

import frontImage from 'images/fachada_1.jpg'
// import consultorio from 'images/consultorio1.jpg'

const SobreNos = () => {
  return (
    <Layout>
      <>
        <TitleBox title='Sobre nós' bg={frontImage} />
        <PageContainer>
          <Fade>
            <ContentWrapper>
              <UnderlinedText>Clínica Veterinária Derosso</UnderlinedText>
              <ContentText>
                A Clínica Veterinária Derosso já perdeu a conta dos animais que
                cuidou na região de Curitiba e São José dos Pinhais, Desde sua
                inauguração, em 1992, o bem estar dos bichos e a alegria de seus
                donos vendo-os saudáveis, é a nossa grande recompensa, Uma ampla
                estrutura além de alta capacitação técnica, encontra-se a
                disposição 24 horas por dia, atendendo finais de semana e
                feriado qualquer emergência. Totalmente equipada para consultas
                clínicas, inclusive cirurgias, a Veterinária Derosso possui uma
                equipe com especializações em Dermatologia, Odontologia,
                Anestesiologia e Ortopedia. Realizamos diagnóstico por imagem!.
              </ContentText>
            </ContentWrapper>
            <img src={frontImage} alt='faixada_da_veterinária' />
          </Fade>
        </PageContainer>
      </>
    </Layout>
  )
}

export default SobreNos
